@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/gridle";@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/colors";
.list {
	@extend %text-secondary;
	list-style: disc;
	margin-left: 17px;
	text-align: left;

	&__item {
		margin-bottom: 5px;
		display: list-item;
		align-items: center;

		&--custom {
			list-style: none;
			align-items: flex-start;
			display: flex;
		}

		&__icon {
			display: flex;
			justify-content: center;
			align-items: center;

			svg {
				margin-right: 15px;
				width: 20px;
				height: 20px;
			}
		}

		&:last-of-type {
			margin-bottom: 0;
		}
	}
}
