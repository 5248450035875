@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/gridle";@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/colors";
.line-clamp-view-more {
	position: relative;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;

	&--show-all {
		-webkit-box-orient: initial;
		white-space: initial;
	}

	&--mutiline {
		white-space: initial;
		overflow: hidden;
		display: -webkit-box;
		text-overflow: ellipsis;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;

		p {
			display: inline; // bug sur safari ios
		}
	}

	&__view-more {
		position: absolute;
		bottom: 0;
		right: 0;
		background: white;

		a {
			@extend %link;
			font-size: 1.1rem;
			text-decoration: underline;
			margin-left: 5px;
		}
	}

	&__reduce {
		a {
			@extend %link;
			font-size: 1.1rem;
			text-decoration: underline;
			margin-left: 5px;
		}
	}
}
