@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/gridle";@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/colors";
$button-primary-background-color: $color-secondary !default;

.loading-bar {
	position: fixed;
	top: 0;
	width: 100vw;
	transform: translate(-100%);
	background: $button-primary-background-color;
	height: 5px;
	z-index: 10;

	&--loading {
		transform: translate(-5%);
		transition: transform 6s linear;
	}

	&--finished {
		transform: translate(0%);
		transition-duration: 0.5s;
	}
}
