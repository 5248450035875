@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/gridle";@import "/Users/thierrylau/workspace/front/src/app/pages/.styles/colors";
$ps-shop-list: de-CH, de-DE, de-AT, de-NL, en-GB, es-ES, fr-CH, fr-FR, it-IT, nl-BE, fr-BE;
$xx-shop-list: de-CH, de-DE, de-AT, de-NL, en-GB, es-ES, fr-CH, fr-FR, it-IT, nl-BE, fr-BE;
$vp-shop-list: de-CH, de-DE, de-AT, de-NL, en-GB, es-ES, fr-CH, fr-FR, it-IT;
$af-shop-list: fr-FR, en-GB, fr-BE, de-DE, es-ES, it-IT;
$tz-shop-list: fr-FR, en-GB, de-DE, es-ES;
$gp-shop-list: fr-FR, en-GB, de-DE, it-IT;
$pv-shop-list: fr-FR, es-ES, it-IT;
$hp-shop-list: fr-FR, de-DE, it-IT, en-GB;
$ax-shop-list: fr-FR, en-GB;

.logo-brand {
	&__logo {
		max-width: 100%;
	}

	//TODO : soon to be legacy; when it is, delete all below

	display: inline-block;
	height: 60px;
	width: 200px;
	background-size: contain;
	background-repeat: no-repeat;
	background-position-x: center;
	background-position-y: center;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);

	@each $ps-shop in $ps-shop-list {
		&--PS-#{$ps-shop} {
			background-image: url("https://res.cloudinary.com/perfectstay/image/upload/icon-logo/icon-logo-PS-fr-FR.svg");

			&-white {
				background-image: url("https://res.cloudinary.com/perfectstay/image/upload/icon-logo/icon-logo-PS-fr-FR-white.svg");
			}
		}
	}

	@each $xx-shop in $xx-shop-list {
		&--XX-#{$xx-shop} {
			background-image: url("https://res.cloudinary.com/perfectstay/image/upload/icon-logo/icon-logo-PS-fr-FR.svg");

			&-white {
				background-image: url("https://res.cloudinary.com/perfectstay/image/upload/icon-logo/icon-logo-PS-fr-FR-white.svg");
			}
		}
	}

	@each $pv-shop in $pv-shop-list {
		&--PV-#{$pv-shop} {
			background-image: url("https://res.cloudinary.com/perfectstay/image/upload/icon-logo/icon-logo-PV-#{$pv-shop}.svg");
			min-width: 150px; // logo privalia trop long. on evite qu'il ecrase le picto telephone

			&-white {
				background-image: url("https://res.cloudinary.com/perfectstay/image/upload/icon-logo/icon-logo-PV-#{$pv-shop}.svg");
			}

			@include gridle_state(md) {
				min-width: 300px;
			}
		}
	}

	@each $tz-shop in $tz-shop-list {
		&--TZ-#{$tz-shop} {
			background-image: url("https://res.cloudinary.com/perfectstay/image/upload/icon-logo/icon-logo-TZ-fr-FR.svg");

			&-white {
				background-image: url("https://res.cloudinary.com/perfectstay/image/upload/icon-logo/icon-logo-TZ-fr-FR-white.svg");
			}
		}
	}

	@each $ax-shop in $ax-shop-list {
		&--AX-#{$ax-shop} {
			background-image: url("https://res.cloudinary.com/perfectstay/image/upload/icon-logo/icon-logo-AX-fr-FR.svg");
			height: 60px;
			width: 240px;

			&-white {
				background-image: url("https://res.cloudinary.com/perfectstay/image/upload/icon-logo/icon-logo-AX-fr-FR-white.svg");
			}
		}
	}

	@each $gp-shop in $gp-shop-list {
		&--GP-#{$gp-shop} {
			background-image: url("https://res.cloudinary.com/perfectstay/image/upload/icon-logo/icon-logo-GP-fr-FR.svg");
			max-height: 30px;

			&-white {
				background-image: url("https://res.cloudinary.com/perfectstay/image/upload/icon-logo/icon-logo-GP-fr-FR-white.svg");
			}
		}
	}

	&--CD-fr-FR {
		background-image: url("https://res.cloudinary.com/perfectstay/image/upload/icon-logo/icon-logo-CD-fr-FR.svg");

		&-white {
			background-image: url("https://res.cloudinary.com/perfectstay/image/upload/icon-logo/icon-logo-CD-fr-FR-white.svg");
		}
	}

	&--AR-de-DE {
		background-image: url("https://res.cloudinary.com/perfectstay/image/upload/icon-logo/icon-logo-AR-de-DE.png");

		&-white {
			background-image: url("https://res.cloudinary.com/perfectstay/image/upload/icon-logo/icon-logo-AR-de-DE.png");
		}
	}

	&--AR-de-DE {
		background-image: url("https://res.cloudinary.com/perfectstay/image/upload/icon-logo/icon-logo-AR-de-DE.png");

		&-white {
			background-image: url("https://res.cloudinary.com/perfectstay/image/upload/icon-logo/icon-logo-AR-de-DE.png");
		}
	}

	@each $af-shop in $af-shop-list {
		&--AF-#{$af-shop} {
			background-image: url("https://res.cloudinary.com/perfectstay/image/upload/icon-logo/icon-logo-AF-fr-FR.svg");

			&-white {
				background-image: url("https://res.cloudinary.com/perfectstay/image/upload/icon-logo/icon-logo-AF-fr-FR-white.svg");
			}
		}
	}

	// TODO code à supprimer quand AFFR meurt
	&--AFH-fr-FR {
		background-image: url("https://res.cloudinary.com/perfectstay/image/upload/icon-logo/icon-logo-AFH-fr-FR.svg");

		&-white {
			background-image: url("https://res.cloudinary.com/perfectstay/image/upload/icon-logo/icon-logo-AFH-fr-FR-white.svg");
		}
	}

	&--EK-fr-FR {
		background-image: url("https://res.cloudinary.com/perfectstay/image/upload/icon-logo/icon-logo-EH-fr-FR.svg");
		width: 70px;

		&-white {
			background-image: url("https://res.cloudinary.com/perfectstay/image/upload/icon-logo/icon-logo-EH-fr-FR.svg");
		}
	}

	&--PV-fr-FR {
		background-image: url("https://res.cloudinary.com/perfectstay/image/upload/icon-logo/icon-logo-VP-fr-FR.svg");

		&-white {
			background-image: url("https://res.cloudinary.com/perfectstay/image/upload/icon-logo/icon-logo-VP-fr-FR-white.svg");
		}
	}

	@each $vp-shop in $vp-shop-list {
		&--VP-#{$vp-shop} {
			vertical-align: middle;
			background-image: url("https://res.cloudinary.com/perfectstay/image/upload/icon-logo/icon-logo-VP-#{$vp-shop}.svg");

			&-white {
				background-image: url("https://res.cloudinary.com/perfectstay/image/upload/icon-logo/icon-logo-VP-#{$vp-shop}-white.svg");
			}
		}
	}

	&--DD-fr-CH,
	&--DD-de-CH {
		background-image: url("https://res.cloudinary.com/perfectstay/image/upload/icon-logo/icon-logo-DD-fr-CH.svg");

		&-white {
			background-image: url("https://res.cloudinary.com/perfectstay/image/upload/icon-logo/icon-logo-DD-fr-CH-white.svg");
		}
	}

	&--MS-fr-CH,
	&--MS-de-CH {
		background-image: url("https://res.cloudinary.com/perfectstay/image/upload/icon-logo/icon-logo-MS-fr-CH.svg");

		&-white {
			background-image: url("https://res.cloudinary.com/perfectstay/image/upload/icon-logo/icon-logo-MS-fr-CH-white.svg");
		}
	}

	@each $hp-shop in $hp-shop-list {
		&--HP-#{$hp-shop} {
			background-image: url("https://res.cloudinary.com/perfectstay/image/upload/icon-logo/icon-logo-HP-#{$hp-shop}.svg");

			&-white {
				background-image: url("https://res.cloudinary.com/perfectstay/image/upload/icon-logo/icon-logo-HP-#{$hp-shop}-white.svg");
			}
		}
	}

	&--TO-fr-FR {
		background-image: url("https://res.cloudinary.com/perfectstay/image/upload/icon-logo/icon-logo-TO-fr-FR-1.svg");
		height: 45px;
		width: 165px;

		&-white {
			background-image: url("https://res.cloudinary.com/perfectstay/image/upload/icon-logo/icon-logo-TO-fr-FR-white-1.svg");
		}
	}

	&--VC-fr-FR {
		background-image: url("https://res.cloudinary.com/perfectstay/image/upload/icon-logo/icon-logo-verychic.png");

		&-white {
			background-image: url("https://res.cloudinary.com/perfectstay/image/upload/icon-logo/icon-logo-VC-fr-FR-white.svg");
		}
	}

	&--UG-fr-FR {
		background-image: url("https://res.cloudinary.com/perfectstay/image/upload/icon-logo/icon-logo-UG-de-AT.svg");

		&-white {
			background-image: url("https://res.cloudinary.com/perfectstay/image/upload/icon-logo/icon-logo-UG-de-AT.svg");
		}
	}

	&--UG-de-AT {
		background-image: url("https://res.cloudinary.com/perfectstay/image/upload/icon-logo/icon-logo-UG-de-AT.svg");

		&-white {
			background-image: url("https://res.cloudinary.com/perfectstay/image/upload/icon-logo/icon-logo-UG-de-AT.svg");
		}
	}

	&--UG-de-DE {
		background-image: url("https://res.cloudinary.com/perfectstay/image/upload/icon-logo/icon-logo-UG-de-AT.svg");

		&-white {
			background-image: url("https://res.cloudinary.com/perfectstay/image/upload/icon-logo/icon-logo-UG-de-AT.svg");
		}
	}

	&--VE-fr-BE {
		background-image: url("https://res.cloudinary.com/perfectstay/image/upload/icon-logo/icon-logo-VE-fr-BE.svg");

		&-white {
			background-image: url("https://res.cloudinary.com/perfectstay/image/upload/icon-logo/icon-logo-VE-fr-BE.svg");
		}
	}

	&--VE-nl-BE {
		background-image: url("https://res.cloudinary.com/perfectstay/image/upload/icon-logo/icon-logo-VE-nl-BE.svg");

		&-white {
			background-image: url("https://res.cloudinary.com/perfectstay/image/upload/icon-logo/icon-logo-VE-nl-BE.svg");
		}
	}

	&--SE-fr-FR {
		background-image: url("https://res.cloudinary.com/perfectstay/image/upload/icon-logo/icon-logo-SE-fr-FR.svg");

		&-white {
			background-image: url("https://res.cloudinary.com/perfectstay/image/upload/icon-logo/icon-logo-SE-fr-FR-white.svg");
		}
	}

	&--SE-it-IT {
		background-image: url("https://res.cloudinary.com/perfectstay/image/upload/icon-logo/icon-logo-SE-it-IT.svg");

		&-white {
			background-image: url("https://res.cloudinary.com/perfectstay/image/upload/icon-logo/icon-logo-SE-it-IT-white.svg");
		}
	}

	&--SE-en-GB {
		background-image: url("https://res.cloudinary.com/perfectstay/image/upload/icon-logo/icon-logo-SE-en-GB.svg");

		&-white {
			background-image: url("https://res.cloudinary.com/perfectstay/image/upload/icon-logo/icon-logo-SE-en-GB-white.svg");
		}
	}

	&--LS-fr-FR {
		background-image: url("https://res.cloudinary.com/perfectstay/image/upload/icon-logo/icon-logo-LS-fr-FR.svg");

		&-white {
			background-image: url("https://res.cloudinary.com/perfectstay/image/upload/icon-logo/icon-logo-LS-fr-FR-white.svg");
		}
	}

	&--EH-fr-FR {
		background-image: url("https://res.cloudinary.com/perfectstay/image/upload/icon-logo/icon-logo-EH-fr-FR.svg");
		width: 70px;

		&-white {
			background-image: url("https://res.cloudinary.com/perfectstay/image/upload/icon-logo/icon-logo-EH-fr-FR.svg");
		}
	}

	&--SP-fr-FR {
		background-image: url("https://res.cloudinary.com/perfectstay/image/upload/icon-logo/icon-logo-SP-fr-FR.svg");
		width: 300px;

		&-white {
			background-image: url("https://res.cloudinary.com/perfectstay/image/upload/icon-logo/icon-logo-SP-fr-FR-white.svg");
		}
	}

	&--OV-fr-FR {
		background-image: url("https://res.cloudinary.com/perfectstay/image/upload/icon-logo/icon-logo-OV-fr-FR.svg");
		width: 250px;

		&-white {
			background-image: url("https://res.cloudinary.com/perfectstay/image/upload/icon-logo/icon-logo-OV-fr-FR-white.svg");
		}
	}
}
